import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import RoundedButton from "../components/Buttons/RoundedButton";
import { useAppDispatch } from "../store";
import DefaultInput from "../components/Inputs/DefaultInput";
import Layout from "../components/Layout";
import styles from "../styles/ForgotPassword.module.scss";
import { sendForgotPasswordLink } from "../store/passwordReset/actions";
import {
  selectPasswordResetLoading,
  selectSuccessfullySent,
} from "../store/passwordReset/selectors";
import {
  resetSuccessfullySent,
  resetSuccessfulPasswordUpdate,
} from "../store/passwordReset/reducer";
import FullScreenLoading from "../components/Loading/FullScreenLoading";
import Footer from "../components/Home/internal/Footer";

const ResetPage = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector((state) => selectPasswordResetLoading(state));
  const successfullySent = useSelector((state) =>
    selectSuccessfullySent(state)
  );

  const [email, setEmail] = useState("");

  const sendReset = () => {
    dispatch(sendForgotPasswordLink(email));
  };

  useEffect(
    () =>
      // reset state when you leave this page
      () => {
        dispatch(resetSuccessfullySent());
        dispatch(resetSuccessfulPasswordUpdate());
      },
    []
  );

  return (
    <Layout
      noIndex
      hideFooter
      canonical="https://bidddy.com/reset/"
      image="https://imgix.cosmicjs.com/1ea993f0-de97-11eb-a12b-17ba28648e41-HomeOgImage.png"
      description="Bidddy is a tool that allows you to run comment driven auctions on social media. This provides businesses of any size a new way to grow sales and online presence."
      title="Password Reset - Bidddy | The World's First Automated Auctions for Social Media"
    >
      <div className={styles.forgotPasswordContainer}>
        <h1>Forgot Password</h1>
        <p>
          Enter your email and we will send you a link to reset your password.
        </p>
        {!successfullySent && (
          <div className={styles.formContainer}>
            <label>Email</label>
            <DefaultInput
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
            />
            <RoundedButton disabled={email === ""} onClick={() => sendReset()}>
              Reset Password
            </RoundedButton>
          </div>
        )}
        {successfullySent && (
          <div className={styles.successContainer}>
            <div
              className={cn([
                {
                  [styles.circleLoader]: isLoading,
                  [styles.loadComplete]: !isLoading,
                },
                styles.loaderContainer,
              ])}
            >
              <div className={cn([styles.checkmark, styles.draw])} />
            </div>
            <p>
              Success! Please check your inbox for instructions on your password
              reset.
            </p>
          </div>
        )}
      </div>
      {isLoading && <FullScreenLoading />}
      <Footer />
    </Layout>
  );
};

export default ResetPage;
